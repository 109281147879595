import { fetchNews } from "./newsfeed";

document.addEventListener('DOMContentLoaded', function() {
    const searchForm = document.getElementById('search-form');
    const searchResults = document.getElementById('search-results');
    const searchInput = document.getElementById('search-input');

    if (!searchForm) {
        return;
    }
    
    const url = searchForm.action;

    function performSearch(tag = null) {
        //e.preventDefault(); // Prevent the default form submission

        const query = searchForm.query.value; // Get the value from the input field

        var urlObj = new URL(url);
        var params = new URLSearchParams(urlObj.search);
        if (query) {
            params.set('query', query);
        }
        if (tag) {
            params.set('tags', tag);
        }
        urlObj.search = params.toString();
        var newUrl = urlObj.toString();

        // Fetch the search results
        fetch(newUrl)
            .then(response => response.text())
            .then(data => {
                const tempContainer = document.createElement('div');
                tempContainer.innerHTML = data;

                // Get all top-level divs
                const topLevelDivs = Array.from(tempContainer.children).filter(child => child.tagName === 'DIV');

                // Create a new HTML string with <hr> between top-level divs
                const resultWithHr = topLevelDivs.map(div => div.outerHTML).join('<hr>');

                // Load the results into the div
                searchResults.innerHTML = resultWithHr;

                const newsLinks = document.querySelectorAll('.text-link');

                // Add a click event listener to each link
                newsLinks.forEach(link => {
                    if (link.classList.contains("read-news")) {
                        link.addEventListener('click', fetchNews);
                    }
                });
            })
            .catch(error => {
                console.error('Error fetching search results:', error);
            });
    }

    fetch(url)
        .then(response => response.text())
        .then(data => {
            const tempContainer = document.createElement('div');
            tempContainer.innerHTML = data;

            // Get all top-level divs
            const topLevelDivs = Array.from(tempContainer.children).filter(child => child.tagName === 'DIV');

            // Create a new HTML string with <hr> between top-level divs
            const resultWithHr = topLevelDivs.map(div => div.outerHTML).join('<hr>');

            // Load the results into the div
            searchResults.innerHTML = resultWithHr;

            const newsLinks = document.querySelectorAll('.text-link');

            // Add a click event listener to each link
            newsLinks.forEach(link => {
                if (link.classList.contains("read-news")) {
                    link.addEventListener('click', fetchNews);
                }
            });
        })
        .catch(error => {
            console.error('Error fetching search results:', error);
        });

    let timeout;

    // Handle Enter key submission
    searchInput.addEventListener('keypress', function(event) {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission
            performSearch(searchInput.value);
        }
    });

    // Handle debounced search
    searchInput.addEventListener('input', function() {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            performSearch(searchInput.value);
        }, 500);
    });

    var tagButtons = document.querySelectorAll('.search-button-list .tag');
    tagButtons.forEach(function(tagButton) {
        tagButton.addEventListener('click', function() {
            // check if the button is already active
            if (tagButton.classList.contains('active')) {
                return;
            }
            performSearch(tagButton.dataset.tag);
            // Remove the active class from all buttons and add it to the clicked button
            tagButtons.forEach(function(button) {
                button.classList.remove('active');
            });
            tagButton.classList.add('active');
        });
    });
});

document.addEventListener('DOMContentLoaded', function() {
    const projectsContainer = document.querySelector('.home-page .projects-container');

    if (!projectsContainer) {
        return;
    }

    let currentPage = 2; // Start from the first page
    let isLoading = false; // Prevent multiple requests

    // Function to fetch more projects
    function fetchMoreProjects(page) {
        if (isLoading) return; // Prevent multiple requests
        isLoading = true; // Set loading state

        fetch(`/projects/search?format=json&page=${page}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (data.length === 0) {
                    // remove the loader if no more projects are available
                    projectsContainer.removeChild(document.querySelector('.comp-loader'));
                    // remove the scroll event listener
                    return;
                }
                data.forEach(project => {
                    const projectElement = document.createElement('div');
                    projectElement.classList.add('projekt');
                    projectElement.innerHTML = `
                        <div class="image-placeholder" data-src="${project.img}">
                            <span class="loader"></span>
                        </div>
                        <h3>${project.title}</h3>
                        <p>${project.desc}</p>
                        <a href="${project.link}" class="text-link">Mehr erfahren</a>
                    `;

                    projectElement.querySelectorAll('.image-placeholder').forEach(placeholder => {
                        const img = new Image();
                        img.src = placeholder.dataset.src;
                        img.onload = () => {
                            // Replace the placeholder with the loaded image
                            placeholder.replaceWith(img);
                        };
                    });

                    projectsContainer.appendChild(projectElement);
                });
                currentPage++; // Increment the page number for the next request
                isLoading = false; // Reset loading state
                // move <div class="comp-loader"></div> to the end of the container
                projectsContainer.appendChild(document.querySelector('.comp-loader'));
            })
            .catch(error => {
                console.error('Error fetching projects:', error);
                isLoading = false; // Reset loading state on error
            });
    }

    // Scroll event listener
    projectsContainer.addEventListener('scroll', function() {
        // Check if scrolled to the right end of the container
        if (projectsContainer.scrollLeft + projectsContainer.clientWidth >= projectsContainer.scrollWidth - 10) {
            fetchMoreProjects(currentPage); // Fetch more projects
        }
    });
});